@import 'assets/fonts/fonts.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

:root {
  --green: #315549;
  --text_green: #92B0A6;
}

#container {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: "SteppeRegular", sans-serif;
  font-size: 16px;
  line-height: 19px;
}

.app {
  overflow-x: clip;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}


.container {
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
}


