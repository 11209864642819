.modal_calculate {
  margin-bottom: 100px;
  top: 50px;
  max-width: 1300px;
  width: 100% !important;
  padding: 50px;
  background: var(--green);
  color: white;

  .ant-modal-content {
    margin: 0 auto;
    padding: 0;
    position: relative;
    max-width: 960px;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-close {
    color: transparent !important;
    position: absolute;
    top: -100px;

    &:hover {
      color: transparent !important;
      background: transparent !important;
    }
  }

  .close {
    cursor: pointer;
  }

  .h1 {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-family: RfdBold, sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
  }

  .info_text {
    margin-bottom: 40px;
    text-align: center;
    color: #92B0A6;
    font-size: 16px;
    line-height: 19px;
  }

  .filters {
    padding: 15px 30px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    background: var(--text_green);
    border-radius: 20px;
  }

  .block_area {
    padding-bottom: 40px;
    margin-top: 40px;
    font-size: 16px;
    line-height: 19px;
    border-bottom: 1px solid rgba(146, 176, 166, 0.2);

    .select_row {
      margin-top: 20px;
      display: flex;
      align-items: center;

      .select_parent {
        padding: 12.5px 13.5px 14.5px 13.5px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: 1px solid #92B0A6;
        border-radius: 16px;
        transition: all .3s linear;
        cursor: pointer;

        .label {
          max-width: 160px;
          width: 100%;
          white-space: nowrap;
          color: var(--text_green);
        }

        .border {
          width: 1px;
          height: 20px;
          background: var(--text_green);
        }

        .value {
          color: white;
          min-width: 35px;
          text-align: end;
          white-space: nowrap;
        }

        .arrow {
          margin-top: 2px;
          margin-left: 15px;
        }

        .currency {
          margin-left: 15px;
          color: var(--text_green);
        }

        .select_drop {
          position: absolute;
          width: calc(100% + 2px);
          top: 48px;
          left: -1px;
          max-height: 1px;
          opacity: 0;
          visibility: hidden;
          /*background: rgba(146, 176, 166, 0.2);*/
          border: 1px solid #92B0A6;
          background: rgba(0, 0, 0, 0.5);
          backdrop-filter: blur(7.5px);
          border-top-color: transparent;
          /*backdrop-filter: blur(2px);*/
          border-radius: 0 0 16px 16px;
          transition: all .3s linear;

          div {
            padding: 15px;
            color: var(--text_green);
            border-bottom: 1px solid rgba(146, 176, 166, 0.2);
            font-size: 16px;
            line-height: 19px;
            transition: all .3s linear;
            cursor: pointer;

            &:hover {
              color: #F74F38;
            }

            &.active {
              color: white;
            }
          }
        }

        input {
          max-width: 44px;
          background: transparent;
          border: none;
          color: white;
          outline: none;
          text-align: end;

          &::placeholder {
            color: white;
            opacity: .6;
          }
        }

        &:not(:last-child) {
          margin-right: 20px
        }

        &.input {
          cursor: auto;
        }

        &.open {
          border-bottom-color: transparent;
          border-radius: 16px 16px 0 0;
          background: rgba(146, 176, 166, 0.2);
          z-index: 2;

          .select_drop {
            max-height: 200px;
            opacity: 1;
            visibility: initial;
            z-index: 3;
            overflow-y: auto;
          }
        }
      }
    }
  }

  .result {
    margin-top: 40px;

    .h2 {
      padding-bottom: 15px;
      text-align: center;
      font-family: RfdBold, sans-serif;
      width: 100%;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      border-bottom: 1px solid var(--text_green);
    }

    .label_section {
      margin: 40px 0 10px 0;
      color: white;
      font-size: 18px;
    }

    .calculation {
      margin-top: 15px;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid rgba(146, 176, 166, 0.2);

      span {
        font-size: 16px;
        line-height: 19px;
        color: var(--text_green);

        &:last-child {
          color: white;
        }
      }

      &.border {
        border-color: var(--text_green);
      }
    }
  }

  .group_buttons {
    margin-top: 55px;
    display: flex;
    align-items: center;
  }

  .btn_result {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: white;
    background: linear-gradient(91.37deg, #F74F38 0%, #E3371F 100%);
    border-radius: 16px;
    cursor: pointer;
    user-select: none;

    span {
      font-size: 16px;
      line-height: 19px;
    }

    &.clear {
      margin-left: 15px;
      max-width: 250px;
      background: var(--text_green);
    }
  }
}

@media (max-width: 728px) {
  .modal_calculate {
    padding: 15px;
    max-width: calc(100% - 40px);

    .ant-modal-close {
      margin-top: 0;
      margin-right: 30px;

      img {
        width: 25px;
        height: 25px;
      }
    }

    .h1 {
      margin: 0 auto 15px auto;
      font-size: 22px;
      line-height: 26px;
      text-align: center;

      .close {
        width: 25px;
        height: 25px;
      }
    }

    .info_text {
      margin: 0 auto 20px auto;
      font-size: 15px;
      line-height: 16px;
      max-width: 280px;
      text-align: center;
    }

    .filters {
      padding: 15px 15px 0 15px;
      flex-direction: column;
    }

    .h2 {
      text-align: center;
      font-family: RfdBold, sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }

    .block_area {
      margin-top: 22px;
      padding-bottom: 20px;
    }

    .select_row {
      flex-direction: column;

      .select_parent {
        margin: 0 0 20px 0 !important;

      }

      &:last-child {
        margin-top: 0 !important;
      }
    }

    .btn_result {
      padding: 10px;
      margin-top: 20px;
    }
  }
}

@media (max-width: 470px) {
  .modal_calculate {

    .select_row {

      .select_parent {
        flex-direction: column;

        .label {
          border-bottom: 1px solid var(--text_green);
          padding-bottom: 10px;
          margin-bottom: 7px;
          max-width: 100% !important;
          text-align: center;
        }

        .border {
          display: none !important;
        }

        .select_drop {
          top: 42px !important;
        }
      }

      &:last-child {
        margin-top: 0 !important;
      }
    }

    .group_buttons {
      flex-direction: column;

      .clear {
        margin-left: 0;
        max-width: 100%;
      }
    }
  }
}