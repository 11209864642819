.catalog_page {
  max-width: 1230px;
  padding: 50px;
  top: 50px;
  width: 100% !important;

  .h1 {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: RfdBold, sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;

    .close {
      cursor: pointer;
    }
  }
}

@media (max-width: 728px) {
  .catalog_page {
    width: calc(100% - 40px) !important;
    padding: 15px;

    .h1 {
      margin-bottom: 22px;
      font-size: 22px;
      line-height: 26px;
    }
  }
}