.modal_gallery {
  margin-bottom: 100px;
  top: 50px;
  max-width: 1300px;
  width: 100% !important;
  position: static;
  color: white;

  .ant-modal-content {
    position: static;
    margin: 0 auto;
    padding: 0;
    max-width: 960px;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-close {
    color: transparent !important;
    position: absolute;
    top: -100px;

    &:hover {
      color: transparent !important;
      background: transparent !important;
    }
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .h1 {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-family: RfdBold, sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
  }

  .content {
    margin-bottom: 40px;
    text-align: center;
    color: #92B0A6;
    font-size: 16px;
    line-height: 19px;
  }

  .slick-slider {
    margin: 0 auto;
    max-width: 1300px;
    width: 100%;
    @media (max-width: 1320px) {
      margin-left: 0;
    }
  }

  .slick-track:not(:has(.slick-slide:not(.slick-cloned)[data-index="3"])) .slick-cloned {
    display: none !important;
  }

  .slider-gallery .slick-track {
    display: flex;
    gap: 10px;
  }

  .slick-list {
    @media (max-width: 728px) {
      padding: 0 !important;
    }
  }

  .slick-slide img {
    border-radius: 24px;
  }

  .nav-button {
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: 50%;

    &:last-child {
      left: auto;
      right: 20px;
      transform: rotate(180deg);
    }
  }
}

@media (max-width: 728px) {
  .modal_gallery {
    padding: 15px;
    max-width: calc(100% - 40px);

    .ant-modal-close {
      margin-top: 0;
      margin-right: 30px;

      img {
        width: 25px;
        height: 25px;
      }
    }

    .h1 {
      margin: 0 auto 15px auto;
      font-size: 22px;
      line-height: 26px;
      text-align: center;

      .close {
        width: 25px;
        height: 25px;
      }
    }

    .content {
      margin: 0 auto 20px auto;
      font-size: 15px;
      line-height: 16px;
      max-width: 280px;
      text-align: center;
    }
  }
}