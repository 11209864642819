@font-face {
  font-family: 'SteppeRegular';
  src: url('Steppe-Regular.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SteppeBold';
  src: url('Steppe-Bold.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SteppeBlack';
  src: url('Steppe-Black.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'RfdRegular';
  src: url('RFDewi-Regular.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RfdBold';
  src: url('RFDewi-Bold.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RfdBlack';
  src: url('RFDewi-Black.woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


