.modal_feedback {
  /*margin-bottom: 100px;
  top: 50px;*/
  max-width: 600px;
  width: 100% !important;
  padding: 50px 80px !important;
  background: var(--green);
  color: white;

  .ant-modal-content {
    margin: 0 auto;
    padding: 0;
    position: relative;
    max-width: 960px;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    max-width: 470px;
  }

  .title {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .h1 {
      margin-right: 18px;
      font-family: RfdBold, sans-serif;
      font-weight: 700;
      font-size: 38px;
      line-height: 36px;
    }

    .close {
      cursor: pointer;
    }
  }

  .input_parent {
    margin-bottom: 30px;
    width: 100%;
  }

  input,
  .text_area {
    padding: 14px 15px 16px 15px;
    background: transparent;
    width: 100%;
    color: white;
    outline: none;
    border: 1px solid var(--text_green);
    border-radius: 16px;
    font-size: 16px;
    line-height: 19px;

    &::placeholder {
      font-size: 16px;
      line-height: 19px;
      color: var(--text_green);
    }
  }

  .text_area {
    resize: none;
    margin-bottom: 30px;
  }

  .btn_result {
    margin-top: 55px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: white;
    background: linear-gradient(91.37deg, #F74F38 0%, #E3371F 100%);
    border-radius: 16px;
    cursor: pointer;

    span {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

@media (max-width: 728px) {
  .modal_feedback {
    padding: 15px !important;
    top: 20px;
    width: calc(100% - 40px) !important;
    max-width: 345px;

    .title {
      margin-bottom: 22px;

      .h1 {
        font-size: 22px;
        line-height: 26px;
      }

      .close {
        width: 25px;
        height: 25px;
      }
    }

    input,
    .text_area {
      padding: 8px 15px 10px 15px;
      font-size: 14px;
      line-height: 18px;
      &::placeholder {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}